var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"tab-content"},[(_vm.nominations && _vm.nominations.length)?_c('div',{staticClass:"table-responsive mb-0"},[_c('table',{staticClass:"table table-head-custom table-vertical-center table-head-bg table-borderless"},[_vm._m(1),_c('tbody',_vm._l((_vm.nominations),function(nomination){return _c('tr',{key:nomination.id},[_c('td',{attrs:{"title":`${nomination.talent.basicInfo.full_name}`}},[_c('a',{attrs:{"href":`/dashboard/talent/${nomination.talent.id}`,"target":"_blank"}},[_vm._v(" "+_vm._s(nomination.talent.basicInfo.full_name)+" ")])]),_c('td',[_vm._v(_vm._s(nomination.role?.name))]),_c('td',[_c('span',[_vm._v(" "+_vm._s(nomination.start_date)+" ")])]),_c('td',[_c('span',[_vm._v(" "+_vm._s(nomination.end_date)+" ")])]),_c('td',{attrs:{"title":`${nomination?.nominated_by?.name}`}},[_vm._v(" "+_vm._s(nomination?.nominated_by && nomination?.nominated_by?.name ? nomination.nominated_by?.name : "-")+" ")]),_c('td',[_c('span',{staticClass:"status mr-2",style:(`--bg-color:${
                        _vm.rephraseStatus(nomination.status).bgColor
                      }`)},[_vm._v(" "+_vm._s(_vm.rephraseStatus(nomination.status).text)+" ")]),(
                        nomination.status == 'not_interested' &&
                        nomination.rejection_reason
                      )?_c('a',{staticClass:"navi-link",on:{"click":function($event){$event.stopPropagation();return _vm.showRejectionReasonPopup(nomination.rejection_reason)}}},[_c('small',{staticStyle:{"white-space":"nowrap","font-weight":"bold"}},[_vm._v(" Rejection Reason ")])]):_vm._e()])])}),0)])]):_c('h5',{staticClass:"my-5 text-muted text-center d-flex justify-content-center align-items-center",staticStyle:{"min-height":"40rem"}},[_vm._v(" No Nominations Yet ")]),_c('div',{staticClass:"pagination-container"},[(_vm.nominations && _vm.nominations.length > 0)?_c('p',{staticClass:"pagination-container__desc"},[_vm._v(" "+_vm._s(_vm.$t("showing_results", { length: _vm.nominations.length, total: _vm.nominationsMeta.total, }))+" ")]):_vm._e(),(_vm.nominationsMeta && _vm.nominationsMeta.last_page > 1)?_c('b-pagination',{attrs:{"total-rows":_vm.nominationsMeta.total,"per-page":_vm.perPage,"align":"center","last-number":"","first-number":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header py-3"},[_c('div',{staticClass:"card-title flex-column",staticStyle:{"justify-content":"space-evenly"}},[_c('h3',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v(" Nominations List ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"text-left"},[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Role")]),_c('th',[_vm._v("Nomination Start date")]),_c('th',[_vm._v("Nomination End date")]),_c('th',[_vm._v("Nominated by")]),_c('th',[_vm._v("Status")])])])
}]

export { render, staticRenderFns }