<template>
  <!--begin::Card-->
  <div class="card card-custom clients-basic-info">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title flex-column" style="justify-content: space-evenly">
        <h3 class="card-label font-weight-bolder text-dark">Interested List</h3>
      </div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="tab-content">
            <!--begin::Table-->
            <div
              class="table-responsive mb-0"
              v-if="
                nominationList &&
                nominationList.length &&
                nominationList.length > 0
              "
            >
              <table
                class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              >
                <thead>
                  <tr class="text-left">
                    <th style="min-width: 185px">
                      <span>Name/Email</span>
                    </th>
                    <th>Register Date/Type</th>
                    <th>
                      <span style="display: inline-table; width: 150px">
                        <span>Full/Part Time Salary</span>
                        <i
                          class="flaticon2-information icon-md text-info ml-1"
                          v-b-tooltip="`Salary Doesn't include Squadio Margin`"
                        ></i>
                      </span>
                    </th>
                    <th style="min-width: 202px; max-width: 202px">
                      Role/Skills
                    </th>

                    <th class="text-center">Hiring Status</th>
                    <th class="text-center">Qualified</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="talent in nominationList"
                    :key="talent.id"
                    @click="openLinkInNewTab('/dashboard/talent/' + talent.id)"
                    class="cursor-pointer"
                  >
                    <td class="pl-0" style="max-width: 185px">
                      <div class="d-flex align-items-center">
                        <div>
                          <div
                            class="text-dark-75 font-weight-bolder font-size-lg text-clamp-2"
                          >
                            {{ talent.first_name }} {{ talent.last_name }}
                          </div>
                          <div>
                            <span
                              class="text-muted font-size-sm"
                              style="word-break: break-all"
                            >
                              {{ talent.email }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span class="font-weight-bolder font-size-lg">{{
                        new Date(talent.register_at).toLocaleDateString()
                      }}</span>
                      <div>
                        <span
                          class="text-muted font-size-sm"
                          style="word-break: break-all"
                        >
                          {{ talent.registerType == 1 ? "SIGNUP" : "WORKABLE" }}
                        </span>
                      </div>
                    </td>
                    <td style="max-width: 120px">
                      <span class="font-weight-bolder font-size-lg">
                        {{ talent.salary ? "$" + talent.salary : "-" }}</span
                      >
                      /
                      <span class="font-weight-bolder font-size-lg">
                        {{
                          talent.part_time_salary
                            ? "$" + talent.part_time_salary
                            : "-"
                        }}</span
                      >
                    </td>
                    <td style="max-width: 202px">
                      <div
                        class="text-dark-75 font-weight-bolder font-size-lg"
                        v-if="talent.role"
                      >
                        <span v-if="talent.role.name">{{
                          talent.role.name
                        }}</span>
                      </div>
                      <div v-else>-</div>
                      <span class="text-muted" v-if="talent.skills.length"
                        >{{
                          talent.skills
                            .map((item) => item.name)
                            .slice(0, 3)
                            .toString()
                            .replaceAll(",", ", ")
                        }}
                        <span v-if="talent.skills.length > 3">...</span>
                      </span>
                      <div v-else>-</div>
                    </td>
                    <td
                      class="text-center text-center text-dark-75 font-weight-bolder font-size-lg"
                    >
                      {{
                        talent.hiring_status == 0 ||
                        talent.hiring_status == null
                          ? "APPLICANT"
                          : "Hired"
                      }}
                    </td>

                    <td
                      class="text-center text-dark-75 font-weight-bolder font-size-lg"
                    >
                      {{ talent.qualified ? "Yes" : "No" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h5
              class="my-5 text-muted text-center"
              v-if="nominationList && nominationList.length === 0"
            >
              No Results Found
            </h5>

            <!--end::Table-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      page: 1,
      perPage: 8,
      activeQualifiedId: null,
      activeContactedId: null,
      query: {},
      selectedIds: [],
    };
  },
  created() {
    this.handleGettingNominationList();
  },
  watch: {
    page() {
      this.handleGettingNominationList();
    },
    profileDataProject: {
      immediate: true,
      deep: true,
      handler() {
        this.handleGettingNominationList();
      },
    },
  },
  computed: {
    ...mapGetters({
      profile: "clients/getProfileData",
      nominationList: "clients/getNominationList",
      profileDataProject: "clients/getProfileDataProject",
    }),
    talentsQuery() {
      return {
        page: this.page,
        perPage: this.perPage,
        ...this.query,
      };
    },
  },
  methods: {
    ...mapActions({
      getNominationList: "clients/getNominationList",
      getNominationListProject: "clients/getNominationListProject",
    }),
    handleGettingNominationList() {
      if (this.$route.params.project_id) {
        const payload = {
          project_id: this.$route.params.project_id,
        };
        this.getNominationListProject(payload);
      } else {
        const payload = {
          clinet_id: this.$route.params.id,
        };
        this.getNominationList(payload);
      }
    },
    openLinkInNewTab(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
