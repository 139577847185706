<template>
  <!--begin::Card-->
  <div class="card card-custom clients-basic-info">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title flex-column" style="justify-content: space-evenly">
        <h3 class="card-label font-weight-bolder text-dark">Notify Talent</h3>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form" @submit.prevent="save">
      <!--begin::Body-->
      <div class="card-body" style="height: 500px">
        <div class="row">
          <div class="col-12" v-if="roles.length > 0">
            <Carousel
              @handleSkillClick="handleSkillClick($event)"
              :roles="roles"
            />
          </div>
          <div class="col-12" v-if="skillsLoading">
            <v-skeleton-loader
              class="mx-auto"
              type="list-item-two-line"
            ></v-skeleton-loader>
          </div>
          <div class="col-12" v-if="!skillsLoading && roles.length == 0">
            <div class="row d-flex justify-content-center align-items-center">
              <p class="form__error-label">
                There is no roles matchd this request
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-12"
            v-if="roles.length > 0 && !fullStackIsRequired && !skillsLoading"
          >
            <div class="form-group row justify-content-center">
              <div
                class="col-lg-6 col-xl-6 col-form-label"
                v-if="frontEndMatchingSkills.length"
              >
                <template>
                  <v-select
                    label="Front-end Skills"
                    :items="frontEndMatchingFilterSkills"
                    item-text="name"
                    item-value="id"
                    dense
                    hide-details
                    outlined
                    v-model="form.frontEndSkill"
                    @blur="$v.form.frontEndSkill.$touch()"
                    @change="frontEndSkillSearch = ''"
                  >
                    <template v-slot:prepend-item>
                      <div class="px-5 mb-4">
                        <v-text-field
                          v-model.trim="frontEndSkillSearch"
                          placeholder="Search"
                          hide-details
                          class="mt-0 pt-0 text-h6 w-100"
                        ></v-text-field>
                      </div>
                    </template>
                  </v-select>
                  <VulidateError
                    :validationField="$v.form.frontEndSkill"
                    :params="['required']"
                  />
                </template>
              </div>
              <div
                class="col-lg-6 col-xl-6 col-form-label"
                v-if="backEndMatchingSkills.length"
              >
                <template>
                  <v-select
                    label="Back-end Skills"
                    :items="backEndMatchingFilterSkills"
                    item-text="name"
                    item-value="id"
                    dense
                    hide-details
                    outlined
                    v-model="form.backEndSkill"
                    @blur="$v.form.backEndSkill.$touch()"
                    @change="backEndSkillSearch = ''"
                  >
                    <template v-slot:prepend-item>
                      <div class="px-5 mb-4">
                        <v-text-field
                          v-model.trim="backEndSkillSearch"
                          placeholder="Search"
                          hide-details
                          class="mt-0 pt-0 text-h6 w-100"
                        ></v-text-field>
                      </div>
                    </template>
                  </v-select>
                  <VulidateError
                    :validationField="$v.form.backEndSkill"
                    :params="['required']"
                  />
                </template>
              </div>
            </div>
          </div>
          <div class="col-12" v-if="skillsLoading">
            <v-skeleton-loader
              class="mx-auto"
              type="list-item-two-line"
            ></v-skeleton-loader>
          </div>
        </div>
        <div class="row">
          <div
            class="col-12"
            v-if="roles.length > 0 && !fullStackIsRequired && !skillsLoading"
          >
            <div
              class="d-flex justify-content-center align-items-center qualified-controler"
            >
              <b-form-group label="Send to" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="btn-radios-2"
                  v-model="selected"
                  :options="optionsQualifiedList"
                  :aria-describedby="ariaDescribedby"
                  button-variant="outline-primary"
                  size="lg"
                  name="radio-btn-outline"
                  buttons
                ></b-form-radio-group>
              </b-form-group>
            </div>
          </div>
          <div class="col-12" v-if="skillsLoading">
            <v-skeleton-loader
              class="mx-auto"
              type="list-item-two-line"
            ></v-skeleton-loader>
          </div>
        </div>
        <div class="row">
          <div
            class="col-12"
            v-if="roles.length > 0 && !fullStackIsRequired && !skillsLoading"
          >
            <div class="d-flex justify-content-center align-items-center mt-8">
              <div
                v-b-tooltip.hover="
                  !optionsQualified.talents_not_qualified &&
                  !optionsQualified.talents_qualified
                    ? 'There is no talent'
                    : ''
                "
              >
                <v-btn
                  class="mx-4"
                  color="primary"
                  :disabled="
                    !optionsQualified.talents_not_qualified &&
                    !optionsQualified.talents_qualified
                  "
                  large
                  @click="sendNotify"
                  >Send Email</v-btn
                >
              </div>
            </div>
          </div>
          <div class="col-12" v-if="skillsLoading">
            <v-skeleton-loader
              class="mx-auto"
              type="article"
            ></v-skeleton-loader>
          </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center">
          <div
            class="form__error-label"
            v-if="fullStackIsRequired && !skillsLoading"
          >
            You must select the skills to be able to Proceed from the
            <strong class="cursor-pointer" @click="$emit('backToProfile')"
              >technology</strong
            >
            list
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>

    <div class="history-fixed-button">
      <v-btn
        color="primary"
        large
        class="mx-4"
        @click.stop="notifyTalantsHistory = !notifyTalantsHistory"
      >
        History
      </v-btn>
    </div>

    <v-navigation-drawer
      v-model="notifyTalantsHistory"
      right
      fixed
      temporary
      width="auto"
      class="notify-history"
    >
      <v-list-item class="d-flex justify-content-between">
        <h3 class="card-label font-weight-bolder text-dark d-block mr-auto">
          Notify History
        </h3>
        <i
          @click.stop="notifyTalantsHistory = false"
          class="flaticon-cancel text-dark font-weight-bold my-4 cursor-pointer"
          style="font-size: 2rem"
        ></i>
      </v-list-item>
      <div
        style="padding: 0 16px"
        class="d-flex align-items-start mb-2 justify-content-center"
        v-if="!getNominationHistory.length"
      >
        <h6 class="text-muted mb-0">No history data</h6>
      </div>

      <div class="card-body pt-2">
        <!--begin::Item-->
        <div
          v-for="(history, i) in getNominationHistory"
          :key="history.id"
          class="d-flex align-items-center"
          v-bind:class="{ 'mb-3': lastElement(i) }"
        >
          <div class="card card-custom card-stretch w-100">
            <div class="card-body">
              <!--begin::Title-->
              <div
                class="d-flex align-items-start mb-2 justify-content-between"
              >
                <div>
                  <span class="font-weight-bold mr-2">Sent at:</span>
                  <span class="text-dark-50 justify-data">{{
                    dateFormat(history.date_time)
                  }}</span>
                </div>
              </div>

              <div class="d-flex align-items-start mb-2">
                <span class="font-weight-bold mr-2">Sent for </span>
                <span class="text-dark-50 justify-data">
                  {{ history.emails_sent }} {{ history.role }}
                  {{
                    history.qualified == 2
                      ? "Talents"
                      : history.qualified == 1
                      ? "Qualified Talents"
                      : "Registered only talents"
                  }}
                </span>
              </div>

              <div
                class="d-flex align-items-start mb-2 justify-content-between"
                v-if="history.back_end_lang || history.front_end_lang"
              >
                <div v-if="history.back_end_lang" class="mr-4">
                  <span class="font-weight-bold mr-2">Back-end:</span>
                  <span class="text-dark-50 justify-data">{{
                    history.back_end_lang
                  }}</span>
                </div>
                <div>
                  <span
                    class="font-weight-bold mr-2"
                    v-if="history.front_end_lang"
                    >Front-end:</span
                  >
                  <span class="text-dark-50 justify-data">{{
                    history.front_end_lang
                  }}</span>
                </div>
              </div>

              <div class="d-flex align-items-start mb-2">
                <span class="font-weight-bold mr-2">Sent by:</span>
                <span class="text-dark-50 justify-data">{{
                  history.sent_by
                }}</span>
              </div>
            </div>
          </div>

          <!--end::Info-->
        </div>
        <!--end::Item-->
      </div>
    </v-navigation-drawer>
    <!--end::Form-->
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapGetters } from "vuex";
import Carousel from "./Carousel.vue";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import VulidateError from "@/components/shared/vulidateError.vue";
import { required } from "vuelidate/lib/validators";

export default {
  name: "NotifyTalants",
  mixins: [validationMixin],
  props: {
    profile: {
      required: true,
      type: Object,
    },
  },
  components: {
    Carousel,
    VulidateError,
  },
  data() {
    return {
      notifyTalantsHistory: false,
      frontEndSkillSearch: "",
      backEndSkillSearch: "",
      form: {
        frontEndSkill: "",
        backEndSkill: "",
      },
      selected: 2,
      options: [
        { disabled: true, text: "All", value: 2 },
        { disabled: true, text: "Qualified", value: 1 },
        { disabled: true, text: "Registered only", value: 0 },
      ],
      role: {},
      optionsQualified: {
        talents_not_qualified: 0,
        talents_qualified: 0,
        type: "role",
      },
      fullStackIsRequired: false,
      skillsLoading: false,
    };
  },
  validations() {
    let validations = {};

    if (
      this.frontEndMatchingSkills.length > 0 &&
      this.backEndMatchingSkills.length > 0
    ) {
      validations = {
        form: {
          frontEndSkill: {
            required,
          },
          backEndSkill: {
            required,
          },
        },
      };
    } else if (this.frontEndMatchingSkills.length > 0) {
      validations = {
        form: {
          frontEndSkill: {
            required,
          },
        },
      };
    } else if (this.backEndMatchingSkills.length > 0) {
      validations = {
        form: {
          backEndSkill: {
            required,
          },
        },
      };
    }
    return validations;
  },
  mounted() {
    this.getMatchingRoles();
    this.getNotifiedTalats();
  },
  watch: {
    profileDataProject: {
      immediate: true,
      deep: true,
      handler() {
        this.getMatchingRoles();
        this.getNotifiedTalats();
      },
    },
    role: {
      immediate: true,
      deep: true,
      handler(newRole) {
        this.getRolesTalentsCount(newRole);
      },
    },
    matchingSkills: {
      immediate: true,
      deep: true,
      handler(skill) {
        let front_end = skill.filter((s) => s.front_end == 1);
        let back_end = skill.filter((s) => s.back_end == 1);

        if (
          (!front_end.length || !back_end.length) &&
          this.role.name == "Full Stack"
        ) {
          this.fullStackIsRequired = true;
        } else if (!front_end.length && this.role.name == "Frontend") {
          this.fullStackIsRequired = true;
        } else if (!back_end.length && this.role.name == "Backend") {
          this.fullStackIsRequired = true;
        } else {
          this.fullStackIsRequired = false;
        }
      },
    },
    form: {
      immediate: true,
      deep: true,
      handler(newSkill) {
        if (this.role.name == "Frontend" && newSkill.frontEndSkill) {
          const skill = this.frontEndMatchingSkills.find(
            (sk) => sk.id == newSkill.frontEndSkill
          );
          let payload = {
            role_id: this.role.id,
            role: this.role.name,
            fe_lang_id: skill.id,
            qualified: 0,
            client_project_id: this.$route.params.project_id,
            client_id: this.$route.params.id,
          };

          this.getRolesTalentsNomination(payload).then((res) => {
            this.optionsQualified = {
              ...this.optionsQualified,
              talents_not_qualified: res.count_talents,
              type: "skill",
            };
          });

          payload = {
            role_id: this.role.id,
            role: this.role.name,
            fe_lang_id: skill.id,
            qualified: 1,
            client_id: this.$route.params.id,
            client_project_id: this.$route.params.project_id,
          };

          this.getRolesTalentsNomination(payload).then((res) => {
            this.optionsQualified = {
              ...this.optionsQualified,
              talents_qualified: res.count_talents,
              type: "skill",
            };
          });
        }
        if (this.role.name == "Backend" && newSkill.backEndSkill) {
          const skill = this.backEndMatchingSkills.find(
            (sk) => sk.id == newSkill.backEndSkill
          );
          let payload = {
            role_id: this.role.id,
            role: this.role.name,
            be_lang_id: skill.id,
            qualified: 0,
            client_id: this.$route.params.id,
            client_project_id: this.$route.params.project_id,
          };

          this.getRolesTalentsNomination(payload).then((res) => {
            this.optionsQualified = {
              ...this.optionsQualified,
              talents_not_qualified: res.count_talents,
              type: "skill",
            };
          });

          payload = {
            role_id: this.role.id,
            role: this.role.name,
            be_lang_id: skill.id,
            qualified: 1,
            client_id: this.$route.params.id,
            client_project_id: this.$route.params.project_id,
          };

          this.getRolesTalentsNomination(payload).then((res) => {
            this.optionsQualified = {
              ...this.optionsQualified,
              talents_qualified: res.count_talents,
              type: "skill",
            };
          });
        }
        if (
          this.role.name == "Full Stack" &&
          newSkill.backEndSkill &&
          newSkill.frontEndSkill
        ) {
          const Backskill = this.backEndMatchingSkills.find(
            (sk) => sk.id == newSkill.backEndSkill
          );
          const Frontskill = this.frontEndMatchingSkills.find(
            (sk) => sk.id == newSkill.frontEndSkill
          );

          let payload = {
            role_id: this.role.id,
            role: this.role.name,
            fe_lang_id: Frontskill.id,
            be_lang_id: Backskill.id,
            qualified: 0,
            client_id: this.$route.params.id,
            client_project_id: this.$route.params.project_id,
          };

          this.getRolesTalentsNomination(payload).then((res) => {
            this.optionsQualified = {
              ...this.optionsQualified,
              talents_not_qualified:
                this.optionsQualified.talents_not_qualified + res.count_talents,
              type: "skill",
            };
          });

          payload = {
            role_id: this.role.id,
            role: this.role.name,
            fe_lang_id: Frontskill.id,
            be_lang_id: Backskill.id,
            qualified: 1,
            client_id: this.$route.params.id,
            client_project_id: this.$route.params.project_id,
          };

          this.getRolesTalentsNomination(payload).then((res) => {
            this.optionsQualified = {
              ...this.optionsQualified,
              talents_qualified:
                this.optionsQualified.talents_qualified + res.count_talents,
              type: "skill",
            };
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      roles: "clients/getRoles",
      matchingSkills: "clients/getMatchingSkills",
      getNominationHistory: "clients/getNominationHistory",
      profileDataProject: "clients/getProfileDataProject",
    }),
    optionsQualifiedList() {
      return [
        {
          disabled:
            this.optionsQualified.talents_not_qualified == 0 &&
            this.optionsQualified.talents_qualified == 0,
          text: `All ${
            this.optionsQualified.talents_not_qualified +
              this.optionsQualified.talents_qualified >
            0
              ? "(" +
                (this.optionsQualified.talents_not_qualified +
                  this.optionsQualified.talents_qualified) +
                ")"
              : ""
          }`,
          value: 2,
        },
        {
          disabled: this.optionsQualified.talents_qualified == 0,
          text: `Qualified ${
            this.optionsQualified.talents_qualified > 0
              ? "(" + this.optionsQualified.talents_qualified + ")"
              : ""
          }`,
          value: 1,
        },
        {
          disabled: this.optionsQualified.talents_not_qualified == 0,
          text: `Registered only ${
            this.optionsQualified.talents_not_qualified > 0
              ? "(" + this.optionsQualified.talents_not_qualified + ")"
              : ""
          }`,
          value: 0,
        },
      ];
    },
    frontEndMatchingSkills() {
      return this.matchingSkills.filter((skill) => skill.front_end == 1) || [];
    },
    frontEndMatchingFilterSkills() {
      return (
        this.frontEndMatchingSkills.filter((skill) =>
          skill.name
            .toLowerCase()
            .includes(this.frontEndSkillSearch.toLowerCase())
        ) || []
      );
    },
    backEndMatchingSkills() {
      return this.matchingSkills.filter((skill) => skill.back_end == 1) || [];
    },
    backEndMatchingFilterSkills() {
      return (
        this.backEndMatchingSkills.filter((skill) =>
          skill.name
            .toLowerCase()
            .includes(this.backEndSkillSearch.toLowerCase())
        ) || []
      );
    },
  },
  methods: {
    ...mapActions({
      getRoles: "clients/getRoles",
      getRolesProject: "clients/getRolesProject",
      getMatchingSkills: "clients/getMatchingSkills",
      getMatchingSkillsProject: "clients/getMatchingSkillsProject",
      nominateTalant: "clients/nominateTalant",
      nominateTalantProject: "clients/nominateTalantProject",
      setNominationHistory: "clients/setNominationHistory",
      setNominationHistoryProject: "clients/setNominationHistoryProject",
      getRolesTalentsNomination: "clients/getRolesTalentsNomination",
    }),
    getRolesTalentsCount(newRole) {
      this.form = {
        frontEndSkill: "",
        backEndSkill: "",
      };
      if (
        newRole.name !== "Frontend" &&
        newRole.name !== "Backend" &&
        newRole.name !== "Full Stack" &&
        newRole.name !== "No Code"
      ) {
        this.optionsQualified = {
          talents_not_qualified: newRole.talents_not_qualified,
          talents_qualified: newRole.talents_qualified,
          type: "role",
        };
      } else {
        this.optionsQualified = {
          talents_not_qualified: 0,
          talents_qualified: 0,
          type: "skill",
        };
        if (newRole.name === "No Code") {
          let payload = {
            role_id: this.role.id,
            role: this.role.name,
            qualified: 0,
            client_id: this.$route.params.id,
            client_project_id: this.$route.params.project_id,
          };
          this.getRolesTalentsNomination(payload).then((res) => {
            this.optionsQualified = {
              ...this.optionsQualified,
              talents_not_qualified: res.count_talents,
              type: "skill",
            };
          });
          payload = {
            role_id: this.role.id,
            role: this.role.name,
            qualified: 1,
            client_id: this.$route.params.id,
            client_project_id: this.$route.params.project_id,
          };
          this.getRolesTalentsNomination(payload).then((res) => {
            this.optionsQualified = {
              ...this.optionsQualified,
              talents_qualified: res.count_talents,
              type: "skill",
            };
          });
        }
      }
    },
    dateFormat(date) {
      return dayjs(date).format("DD/MM/YYYY HH:mm a");
    },
    lastElement(i) {
      if (i === this.getNominationHistory.length - 1) {
        return false;
      } else {
        return true;
      }
    },
    getNotifiedTalats() {
      if (this.$route.params.project_id) {
        const payload = {
          project_id: this.$route.params.project_id,
        };
        this.setNominationHistoryProject(payload);
      } else {
        const payload = {
          clinet_id: this.$route.params.id,
        };
        this.setNominationHistory(payload);
      }
    },
    getMatchingRoles() {
      if (this.$route.params.project_id) {
        const payload = {
          project_id: this.$route.params.project_id,
        };
        this.getRolesProject(payload).then(() => {
          this.role_id = this.roles[0].id;
          this.role = this.roles[0];
          if (!this.role_id) return;
          this.handleSkillClick(this.role_id);
        });
      } else {
        const payload = {
          clinet_id: this.$route.params.id,
        };
        this.getRoles(payload).then(() => {
          this.role_id = this.roles[0].id;
          this.role = this.roles[0];
          if (!this.role_id) return;
          this.handleSkillClick(this.role_id);
        });
      }
    },
    handleSkillClick(role_id) {
      this.role_id = role_id;
      this.role = this.roles.find((r) => r.id === role_id);
      if (this.$route.params.project_id) {
        const payload = {
          role_id,
          project_id: this.$route.params.project_id,
        };
        this.skillsLoading = true;
        this.getMatchingSkillsProject(payload).then(
          () => (this.skillsLoading = false)
        );
      } else {
        const payload = {
          role_id,
          clinet_id: this.$route.params.id,
        };
        this.skillsLoading = true;
        this.getMatchingSkills(payload).then(
          () => (this.skillsLoading = false)
        );
      }
    },
    sendNotify() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }

      let payload = {
        role_id: this.role_id,
        role: this.roles.find((role) => role.id === this.role_id).name,
        be_lang_id: this.form.backEndSkill,
        fe_lang_id: this.form.frontEndSkill,
        qualified: this.selected,
      };

      if (!payload.be_lang_id) delete payload.be_lang_id;
      if (!payload.fe_lang_id) delete payload.fe_lang_id;

      let front_end_skill = null;

      if (payload.fe_lang_id) {
        front_end_skill = this.matchingSkills.find(
          (s) => s.id == payload.fe_lang_id
        );
      }

      let back_end_skill = null;

      if (payload.be_lang_id) {
        back_end_skill = this.matchingSkills.find(
          (s) => s.id == payload.be_lang_id
        );
      }

      let skills = "";

      if (front_end_skill && back_end_skill) {
        skills = `/ ${front_end_skill.name} and ${back_end_skill.name}`;
      } else if (front_end_skill) {
        skills = `/ ${front_end_skill.name}`;
      } else if (back_end_skill) {
        skills = `/ ${back_end_skill.name}`;
      } else {
        skills = "";
      }

      Swal.fire({
        title: `Are you sure you want to send Emails to all ${
          this.selected == 2
            ? "Talents"
            : this.selected == 1
            ? "Qualified Talents"
            : "Registered only Talents"
        }?`,
        html: `<span>Email content</span> <br /> <br /> <p style='text-align: left;'>We have a new exciting opportunity for you [NAME].
        <br />  <br />
         Wondering would you be interested in a different ${
           this.profile.client_project.hiring_type == 1
             ? "Full-time"
             : this.profile.client_project.hiring_type == 2
             ? "Part-time"
             : "[Full-time/Part-time]"
         } ${this.role.name} ${skills} position, 
         product’s industry is ${
           this.profile.client_project.project_type.name
         }.<br /> <br /> If you are interested please click on apply below.<br /> <br />  Best wishes,<br /> Squadio Team </p>`,
        showCancelButton: true,
        confirmButtonColor: "#6f56c5",
        cancelButtonColor: "#E4E6EF",
        customClass: {
          cancelButton: "text-dark",
          icon: "text-danger border-danger",
        },
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.$route.params.project_id) {
            this.nominateTalantProject({
              body: payload,
              project_id: this.$route.params.project_id,
            }).then(() => {
              this.getMatchingRoles();
            });
          } else {
            this.nominateTalant({
              body: payload,
              clinet_id: this.$route.params.id,
            }).then(() => {
              this.getMatchingRoles();
            });
          }
        }
      });
    },
  },
};
</script>

<style>
.notify-history {
  min-width: 400px;
}

.swal2-container .swal2-html-container {
  max-height: unset;
}
.swal2-popup {
  width: 55em;
}

.form .v-text-field--outlined,
.form .v-text-field--solo {
  border-radius: 4px;
}

.clients-basic-info .form-group {
  margin-bottom: 1rem;
}
</style>

<style lang="scss">
@mixin carousel-item-hover-active {
  border: 1px solid #ffc629;
  border-radius: 20px;
  cursor: pointer;
  img {
    filter: invert(0) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(203%)
      contrast(103%);
  }
  .matching-skills-carousel-item-icon {
    background-color: #ffc629;
  }
}
.matching-skills {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 25px;
  &-carousel {
    position: relative;
    padding: 20px 50px;
    @media (max-width: 768px) {
      padding: 10px;
    }
    &-item {
      text-align: center;
      padding: 10px;
      border: 1px solid #fff;
      @media (min-width: 768px) {
        &:hover {
          @include carousel-item-hover-active;
        }
      }

      &.activeItem,
      &.activeItem:hover {
        @include carousel-item-hover-active;
      }

      &-icon {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        background: #f1f1f1;
        text-align: center;
        display: flex;
        align-items: center;
        padding: 12px;
        margin: 0 auto 10px;
        @media (max-width: 768px) {
          width: 30px;
          height: 30px;
          padding: 5px;
        }
      }
      &-text {
        font-size: 14px;
        font-weight: 500;
        @media (max-width: 768px) {
          font-size: 8px;
        }
      }
    }
  }
  @media (min-width: 768px) {
    .matching-skills-carousel-item {
      &:hover {
        .matching-skills-carousel-item-icon {
          background-color: #ffc629;
        }
      }
    }
  }

  .owl-next,
  .owl-prev {
    position: absolute !important;
    top: 37%;
    display: block !important;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;

    @media (max-width: 575.98px) {
      top: 32%;
    }
  }
  .owl-prev {
    position: absolute;
    top: 37%;
    left: 0px;
    @media (max-width: 768px) {
      left: -10px !important;
    }
    border-right: 15px solid #939a9f;
    &:hover {
      border-right: 15px solid #ffc629;
    }
  }
  .owl-next {
    position: absolute;
    top: 37%;
    right: 0px;
    @media (max-width: 768px) {
      right: -10px !important;
    }

    border-left: 15px solid #939a9f;
    &:hover {
      border-left: 15px solid #ffc629;
    }
  }

  .owl-carousel {
    position: unset !important;
  }
  .owl-theme .owl-nav [class*="owl-"] {
    background: transparent !important;
    font-size: 0 !important;
    padding: 0 !important;
  }

  .owl-theme .owl-nav {
    margin-top: 0 !important;
  }

  .owl-stage-outer {
    margin-bottom: 0;
  }

  .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 0 !important;
  }
  .owl-stage {
    display: flex;
    margin: auto;
  }
  @media (max-width: 768px) {
    .owl-prev {
      left: -13px;
    }
    .owl-next {
      right: -13px;
    }
  }
}

.Skills-carousel .item {
  text-align: center;
  padding: 20px;
  border: 1px solid #fff;
  white-space: nowrap;
  display: inline-block;
}

.Skills-carousel .item:hover,
.Skills-carousel .item.active,
.Skills-carousel .activeItem {
  border: 1px solid #ffc629;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgb(255 198 41 / 20%);
}

.v-navigation-drawer--temporary {
  z-index: 96;
}

.history-fixed-button {
  display: flex;
  position: fixed;
  top: 60%;
  right: 0;
  transform: rotate(90deg) scale(1.1);
  transform-origin: right top;
}

.v-navigation-drawer .card.card-custom > .card-body {
  padding: 1rem 2rem !important;
}

.qualified-controler {
  .btn.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn.btn-outline-primary.dropdown-toggle,
  .show .btn.btn-outline-primary.btn-dropdown {
    color: #ffffff;
    background-color: #6f56c5;
    border-color: #6f56c5;
  }

  .btn.btn-outline-primary {
    color: #6f56c5;
    background-color: transparent;
    border-color: #6f56c5;
  }

  .btn.btn-outline-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
  .btn.btn-outline-primary:focus:not(.btn-text),
  .btn.btn-outline-primary.focus:not(.btn-text) {
    color: #ffffff;
    background-color: #6f56c5;
    border-color: #6f56c5;
  }

  .btn-lg,
  .btn-group-lg > .btn {
    padding: 0.825rem 1.42rem;
    font-size: 1.08rem;
    line-height: 1.5;
    width: 16rem;
  }
}
</style>
