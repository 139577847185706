<template>
  <!--begin::Card-->
  <div class="card card-custom clients-basic-info">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title flex-column" style="justify-content: space-evenly">
        <h3 class="card-label font-weight-bolder text-dark">
          Stakeholders List
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-info font-weight-bolder mr-2"
          :class="{ disabled: clientHasNoProjects }"
          :disabled="clientHasNoProjects"
          @click="openStakeholderModal()"
        >
          Add
        </button>
      </div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="tab-content">
            <!--begin::Table-->
            <h5
              v-if="clientHasNoProjects"
              class="my-5 text-muted text-center d-flex justify-content-center align-items-center"
              style="min-height: 40rem"
            >
              Add project details to be able to add stakeholders
            </h5>
            <div
              class="table-responsive mb-0"
              v-else-if="getStackholderList.length"
            >
              <table
                class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              >
                <thead>
                  <tr class="text-left">
                    <th>State</th>
                    <th>Name</th>
                    <th>Email</th>

                    <th>Phone Number</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class=""
                    v-for="stackHolder in getStackholderList"
                    :key="stackHolder.id"
                  >
                    <td class="text-left pl-0">
                      <b-badge
                        class="ml-2"
                        variant="info"
                        v-if="stackHolder.is_verified"
                      >
                        Verified
                      </b-badge>
                      <b-badge class="ml-2" variant="dark" v-else>
                        Not Verified
                      </b-badge>
                    </td>
                    <td class="stakeholder_name" :title="stackHolder.full_name">
                      {{ stackHolder.full_name }}
                    </td>
                    <td>{{ stackHolder.email }}</td>
                    <td>
                      <span v-if="stackHolder.phone">
                        {{ stackHolder.phone.full_number }} </span
                      ><span v-else> - </span>
                    </td>
                    <td class="stakeholder_actions">
                      <v-btn
                        depressed
                        color="primary"
                        class="mr-2"
                        @click="openStakeholderModal(stackHolder)"
                      >
                        Edit
                      </v-btn>
                      <v-btn
                        depressed
                        dark
                        color="red"
                        @click="confirmDeleteStakeholder(stackHolder)"
                      >
                        Delete
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h5
              class="my-5 text-muted text-center d-flex justify-content-center align-items-center"
              style="min-height: 40rem"
              v-else
            >
              No Stakeholders Yet
            </h5>

            <!--end::Table-->

            <AddStakeholdersModal
              :activeStakeholder="activeStakeholder"
              @reset="activeStakeholder = null"
            >
              <template #default="{ on }">
                <span ref="add-stakeholders-modal" v-on="on"></span>
              </template>
            </AddStakeholdersModal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddStakeholdersModal from "@/components/client-profile/modals/AddStakeholdersModal.vue";
import Swal from "sweetalert2";
import toasted from "@/core/plugins/vue-toasted";

export default {
  data() {
    return {
      page: 1,
      perPage: 8,
      query: {},
      activeStakeholder: null,
      activeStakeholderData: null,
    };
  },
  components: {
    AddStakeholdersModal,
  },
  computed: {
    ...mapGetters({
      getStackholderList: "clients/getStackholderList",
      profile: "clients/getProfileData",
      profileDataProject: "clients/getProfileDataProject",
    }),
    clientHasNoProjects() {
      return !this.profile.projects_list.length && !this.profile.client_project;
    },
    talentsQuery() {
      return {
        page: this.page,
        perPage: this.perPage,
        ...this.query,
      };
    },
  },
  mounted() {
    const payload = { project_id: this.$route.params.project_id };
    this.getAllStackHolders(payload);
  },
  watch: {
    profileDataProject: {
      immediate: true,
      deep: true,
      handler() {
        const payload = { project_id: this.$route.params.project_id };
        this.getAllStackHolders(payload);
      },
    },
  },
  methods: {
    ...mapActions({
      getAllStackHolders: "clients/getAllStackHolders",
      deleteStackHolders: "clients/deleteStackHolders",
    }),
    openStakeholderModal(stakeholder) {
      if (stakeholder) {
        this.activeStakeholder = stakeholder;
      } else {
        this.activeStakeholder = null;
      }
      this.$refs["add-stakeholders-modal"].click();
    },
    confirmDeleteStakeholder(user) {
      Swal.fire({
        title: "Are you sure",
        text: `that you need to delete the Stakeholder ${user.full_name} from the stakeholders list? this Action can not Undo`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#6f56c5",
        cancelButtonColor: "#E4E6EF",
        customClass: {
          cancelButton: "text-dark",
          icon: "text-danger border-danger",
        },
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            project_id: this.$route.params.project_id,
            stakeholder_id: user.id,
          };
          this.deleteStackHolders(payload).then(() => {
            toasted.show("Stakeholder Deleted Successfully", {
              type: "success",
              icon: "check_circle",
            });
          });
        }
      });
    },
  },
};
</script>

<style>
.stakeholder_name {
  max-width: 300px;
}
.stakeholder_actions {
  min-width: 180px;
}
</style>
