<template>
  <div class="modal__heading">
    <h2 class="h1 text-info">{{ modalTitle }}</h2>
    <p class="text-secondary font-size-lg" v-if="modalDesc">{{ modalDesc }}</p>
    <hr />
  </div>
</template>

<script>
export default {
  name: "ModalHeading",
  props: {
    modalTitle: {
      required: true,
      type: String,
    },
    modalDesc: {
      type: String,
    },
  },
};
</script>
