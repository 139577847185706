<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800px"
    content-class="stakeholder-model"
  >
    <template #activator="{ on }">
      <slot :on="on"></slot>
    </template>
    <v-card class="p-5 p-md-8">
      <ModalHeading
        :modalTitle="
          formMode === 'add' ? 'Add New Stakeholder ' : 'Edit Stakeholder'
        "
      />
      <form class="form pt-3" @submit.prevent="submitForm">
        <div class="row">
          <div class="col-md-6 mb-7">
            <label class="form__label"
              >First Name <span class="text-danger">*</span>
            </label>
            <v-text-field
              outlined
              dense
              hide-details
              placeholder="First Name"
              v-model="$v.form.first_name.$model"
              @blur="$v.form.first_name.$touch()"
            >
            </v-text-field>
            <VulidateError
              :validationField="$v.form.first_name"
              :params="[
                'required',
                {
                  maxLength: {
                    number: $v.form.first_name.$params.maxLength.max,
                  },
                },
                'alphaNumericWithSpecialChars',
              ]"
            />
          </div>
          <div class="col-md-6 mb-7">
            <label class="form__label"
              >Last Name <span class="text-danger">*</span></label
            >
            <v-text-field
              outlined
              dense
              hide-details
              placeholder="Last Name"
              v-model="$v.form.last_name.$model"
              @blur="$v.form.last_name.$touch()"
            >
            </v-text-field>
            <VulidateError
              :validationField="$v.form.last_name"
              :params="[
                'required',
                {
                  maxLength: {
                    number: $v.form.last_name.$params.maxLength.max,
                  },
                },
                'alphaNumericWithSpecialChars',
              ]"
            />
          </div>
          <div class="col-md-6 mb-7">
            <label class="form__label"
              >Email Address <span class="text-danger">*</span></label
            >
            <v-text-field
              outlined
              dense
              hide-details
              placeholder="Email Address"
              v-model="form.email"
              @blur="$v.form.email.$touch()"
            >
            </v-text-field>
            <VulidateError
              :validationField="$v.form.email"
              :params="['required', 'email']"
            />
          </div>
          <div class="col-md-6 mb-7">
            <label class="form__label">Phone number</label>
            <!--             <VuePhoneNumberInput
              v-model="form.phone"
              :default-country-code="country_alpha"
              :preferred-countries="['SA', 'EG', 'AE']"
              @update="updatePhoneNumber"
              @blur="$v.form.phone.$touch()"
            /> -->

            <vue-tel-input-vuetify
              outlined
              mode="international"
              :preferredCountries="['SA', 'EG', 'AE']"
              :defaultCountry="country_alpha"
              dense
              name="telephone"
              :inputOptions="{ showDialCode: true }"
              v-model="form.phone"
              @country-changed="updatePhoneNumber"
            ></vue-tel-input-vuetify>
          </div>
        </div>
        <div class="text-right">
          <button
            class="btn btn-secondary font-weight-bolder px-8"
            type="button"
            @click="resetModal"
          >
            Cancel
          </button>
          <button
            class="btn btn-info font-weight-bolder px-8 ml-5"
            :class="{
              'spinner spinner-light spinner-right': isLoadingSubmit,
            }"
            :disabled="isLoadingSubmit"
          >
            Save
          </button>
        </div>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { alphaNumericWithSpecialChars } from "@/helpers";
import ModalHeading from "@/components/UI/ModalHeading.vue";
import VulidateError from "@/components/shared/vulidateError.vue";
import { validationMixin } from "vuelidate";
import { required, email, maxLength } from "vuelidate/lib/validators";
// import VuePhoneNumberInput from "vue-phone-number-input";
// import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  name: "AddStakeholder",
  components: { ModalHeading, VulidateError },
  mixins: [validationMixin],
  props: {
    activeStakeholder: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      formMode: "add",
      isLoadingSubmit: false,
      country_alpha: "SA",
      form: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        countryCode: "+966",
      },
    };
  },
  validations: {
    form: {
      first_name: {
        required,
        maxLength: maxLength(50),
        alphaNumericWithSpecialChars,
      },
      last_name: {
        required,
        maxLength: maxLength(50),
        alphaNumericWithSpecialChars,
      },
      email: {
        required,
        email,
      },
    },
  },
  watch: {
    activeStakeholder(newVal) {
      if (newVal) {
        this.formMode = "edit";
        this.country_alpha = newVal.phone ? newVal.phone.country_alpha2 : "SA";
        setTimeout(() => {
          this.form = {
            first_name: newVal.first_name,
            last_name: newVal.last_name,
            email: newVal.email,
            phone: newVal.phone ? newVal.phone.phone : null,
            countryCode: newVal.phone ? newVal.phone.country_code : "+966",
          };
        }, 100);
      } else {
        this.formMode = "add";
      }
    },
  },

  computed: {
    ...mapGetters({
      profile: "clients/getProfileData",
    }),
  },
  mounted() {
    this.updatePhoneNumber();
  },
  methods: {
    ...mapActions({
      addStackholder: "clients/addStackholder",
      editStackholder: "clients/editStackholder",
    }),
    resetModal() {
      this.dialog = false;
      this.isLoadingSubmit = false;
      this.$emit("reset");
      this.$v.form.$reset();
      this.country_alpha = "SA";
      this.form = {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        countryCode: "+966",
      };
    },
    submitForm() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;
      if (this.formMode == "add") {
        const data = {
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          email: this.form.email,
          country_code: this.form.countryCode,
          phone: this.form.phone
            ?.replace(/\s/g, "")
            .replace(this.form.countryCode, ""),
        };
        const payload = { data, project_id: this.$route.params.project_id };
        this.isLoadingSubmit = true;
        this.addStackholder(payload)
          .then(() => {
            this.resetModal();
          })
          .catch((err) => {
            this.isLoadingSubmit = false;
            console.log(err);
          });
      } else {
        const data = {
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          email: this.form.email,
          country_code: this.form.countryCode,
          phone: this.form.phone
            ?.replace(/\s/g, "")
            .replace(this.form.countryCode, ""),
        };
        const payload = {
          data,
          project_id: this.$route.params.project_id,
          stakeholder_id: this.activeStakeholder.id,
        };
        this.isLoadingSubmit = true;
        this.editStackholder(payload)
          .then(() => {
            this.resetModal();
          })
          .catch((err) => {
            this.isLoadingSubmit = false;
            console.error(err);
          });
      }
    },
    updatePhoneNumber(data) {
      this.form.countryCode = data?.dialCode
        ? "+".concat(data?.dialCode)
        : null;
    },
  },
};
</script>

<style lang="scss">
.stakeholder-model {
  overflow: visible;
}

.vue-phone-number-input {
  cursor: text !important;

  .input-tel__input {
    cursor: text !important;
  }
  .input-tel__label {
    cursor: text !important;
  }
}
</style>
