<template>
  <div class="matching-skills Skills-carouse mb-5">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-12" v-if="roles.length > 0">
          <v-tabs center-active>
            <v-tab
              v-for="(role, i) in roles"
              :key="i"
              :role="role"
              @click="handleSkillClick(role.id)"
              >{{ role.name }}</v-tab
            >
          </v-tabs>
          <!--<carousel
            :items="4"
            :loop="false"
            :margin="10"
            :nav="true"
            :autoWidth="true"
            :dots="false"
            :responsive="{
              0: {
                items: 3
              },
              768: {
                items: 3
              }
            }"
            class="matching-skills-carousel Skills-carousel"
          >
            <skill-card
              v-for="(role, i) in roles"
              :key="i"
              :role="role"
              @handleSkillClick="handleSkillClick($event)"
              :active="activeSillParent"
            />
          </carousel> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* import carousel from "vue-owl-carousel";
import skillCard from "./skillCard.vue"; */
export default {
  /*   components: { carousel, skillCard },
   */ props: ["roles"],
  data() {
    return { activeSillParent: "" };
  },
  watch: {
    roles: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.activeSillParent = newValue[0]?.id;
      },
    },
  },
  methods: {
    handleSkillClick(id) {
      this.activeSillParent = id;
      this.$emit("handleSkillClick", id);
    },
  },
};
</script>

<style lang="scss">
.Skills-carouse .v-tab {
  text-transform: none;
  font-size: 1rem;
  font-weight: 400;
}
</style>
