<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title flex-column" style="justify-content: space-evenly">
        <h3 class="card-label font-weight-bolder text-dark">
          Nominations List
        </h3>
      </div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="tab-content">
            <!--begin::Table-->
            <div
              v-if="nominations && nominations.length"
              class="table-responsive mb-0"
            >
              <table
                class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              >
                <thead>
                  <tr class="text-left">
                    <th>Name</th>
                    <th>Role</th>
                    <th>Nomination Start date</th>
                    <th>Nomination End date</th>
                    <th>Nominated by</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="nomination in nominations" :key="nomination.id">
                    <td :title="`${nomination.talent.basicInfo.full_name}`">
                      <a
                        :href="`/dashboard/talent/${nomination.talent.id}`"
                        target="_blank"
                      >
                        {{ nomination.talent.basicInfo.full_name }}
                      </a>
                    </td>
                    <td>{{ nomination.role?.name }}</td>
                    <td>
                      <span> {{ nomination.start_date }} </span>
                    </td>
                    <td>
                      <span> {{ nomination.end_date }} </span>
                    </td>
                    <td :title="`${nomination?.nominated_by?.name}`">
                      {{
                        nomination?.nominated_by &&
                        nomination?.nominated_by?.name
                          ? nomination.nominated_by?.name
                          : "-"
                      }}
                    </td>
                    <td>
                      <span
                        class="status mr-2"
                        :style="`--bg-color:${
                          rephraseStatus(nomination.status).bgColor
                        }`"
                      >
                        {{ rephraseStatus(nomination.status).text }}
                      </span>
                      <a
                        v-if="
                          nomination.status == 'not_interested' &&
                          nomination.rejection_reason
                        "
                        class="navi-link"
                        @click.stop="
                          showRejectionReasonPopup(nomination.rejection_reason)
                        "
                      >
                        <small style="white-space: nowrap; font-weight: bold">
                          Rejection Reason
                        </small>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h5
              class="my-5 text-muted text-center d-flex justify-content-center align-items-center"
              style="min-height: 40rem"
              v-else
            >
              No Nominations Yet
            </h5>
            <div class="pagination-container">
              <p
                v-if="nominations && nominations.length > 0"
                class="pagination-container__desc"
              >
                {{
                  $t("showing_results", {
                    length: nominations.length,
                    total: nominationsMeta.total,
                  })
                }}
              </p>
              <b-pagination
                v-if="nominationsMeta && nominationsMeta.last_page > 1"
                v-model="page"
                :total-rows="nominationsMeta.total"
                :per-page="perPage"
                align="center"
                last-number
                first-number
              ></b-pagination>
            </div>

            <!--end::Table-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      page: 1,
      perPage: 15,
      query: {},
    };
  },
  computed: {
    ...mapGetters({
      nominations: "clients/getClientNominationsList",
      nominationsMeta: "clients/getClientNominationsListMeta",
    }),
    talentsQuery() {
      return {
        page: this.page,
        perPage: this.perPage,
        ...this.query,
      };
    },
  },
  async mounted() {
    const payload = {
      project_id: this.$route.params.project_id,
      per_page: 15,
      current_page: this.page,
    };
    await this.getClientNominationsList(payload);
  },
  watch: {
    async page() {
      const payload = {
        project_id: this.$route.params.project_id,
        per_page: 15,
        current_page: this.page,
      };
      await this.getClientNominationsList(payload);
    },
  },
  methods: {
    ...mapActions({
      getClientNominationsList: "clients/getClientNominationsList",
    }),
    showRejectionReasonPopup(reason) {
      this.$bvModal.msgBoxOk(reason, {
        title: "Rejection Reason",
        size: "md",
        buttonSize: "md",
        okVariant: "primary",
        headerClass: "p-4 border-bottom-1",
        footerClass: "p-2 border-top-0",
        bodyClass: "text-break",
        centered: true,
      });
    },
    rephraseStatus(status) {
      const statuses = {
        interview_requested: {
          text: "Interview Requested",
          bgColor: "#605189",
        },
        nominated: {
          text: "Nominated",
          bgColor: "#00e9ca",
        },
        future: {
          text: "Future",
          bgColor: "#00e9ca",
        },
        expired: {
          text: "Expired",
          bgColor: "#de3b40",
        },
        not_interested: {
          text: "Not Interested",
          bgColor: "#ffbf00",
        },
      };

      return statuses[status] || { text: status, bgColor: "#605189" };
    },
    openLinkInNewTab(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  display: inline-block;
  white-space: nowrap;
  padding: 7px 15px;
  color: #fff;
  background-color: var(--bg-color);
  border-radius: 4px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
