<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title flex-column" style="justify-content: space-evenly">
        <h3 class="card-label font-weight-bolder text-dark">
          Selected Talents
        </h3>
      </div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="tab-content">
            <!--begin::Table-->
            <div v-if="talents && talents.length" class="table-responsive mb-0">
              <table
                class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              >
                <thead>
                  <tr class="text-left">
                    <th>The talent's name</th>
                    <th>Role</th>
                    <th>Qualified</th>
                    <th>Scheduled on</th>
                    <th>Interview Date</th>
                    <th>Clickup Task ID</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class=""
                    v-for="talent in talents"
                    :key="talent.id"
                    :style="talents.length == 1 ? 'height: 7rem;' : ''"
                  >
                    <td :title="`${talent.talent.full_name}`">
                      <a :href="talent.talent.dashboard_url" target="_blank">{{
                        talent.talent.full_name
                      }}</a>
                    </td>
                    <td>{{ talent.talent.role.name }}</td>
                    <td>
                      {{ talent.talent.is_qualified ? "Yes" : "No" }}
                    </td>

                    <td>
                      <span class="text-center justify-content-center d-flex">
                        {{
                          talent.created_at
                            ? formatDateTime(talent.created_at)
                            : "-"
                        }}
                      </span>
                    </td>
                    <td>
                      <span class="text-center justify-content-center d-flex">
                        {{
                          talent.interview?.meeting_start_time
                            ? formatDateTime(
                                talent.interview.meeting_start_time
                              )
                            : "-"
                        }}
                      </span>
                    </td>

                    <td :style="talents.length == 1 ? 'height: 7rem;' : ''">
                      <div class="d-flex justify-content-center">
                        <div
                          v-if="talent.required_resource?.clickup_task_url"
                          class="d-flex justify-content-center align-items-center"
                        >
                          <a
                            :href="talent?.required_resource?.clickup_task_url"
                            target="_blank"
                            v-b-tooltip.hover="talent?.required_resource?.name"
                          >
                            <img
                              class="d-flex align-items-start"
                              style="height: 2.5rem; width: 2.5rem"
                              src="@/assets/images/clickup.png"
                              alt="clickup"
                            />
                          </a>
                        </div>
                        <div
                          v-if="
                            !talent.required_resource?.clickup_task_url &&
                            checkSelectionTalentsIds(talent.id)
                          "
                        >
                          in progress...
                        </div>
                        <div
                          v-if="
                            !talent.required_resource?.clickup_task_url &&
                            !checkSelectionTalentsIds(talent.id)
                          "
                          class="d-flex justify-content-center align-items-center"
                        >
                          <v-btn
                            class="ma-2 font-weight-bolder"
                            color="success"
                            @click="resyncRequieredResources(talent)"
                          >
                            Auto-Match
                          </v-btn>
                        </div>
                        <v-select
                          v-if="false"
                          style="width: 250px"
                          class="mr-2"
                          label="Clickup task ID"
                          :items="requiredResources"
                          item-text="name"
                          item-value="id"
                          deletable-chips
                          dense
                          hide-details
                          outlined
                          v-model="talent.required_resource"
                          @change="statusDropdownChanged(talent)"
                        />
                        <b-button
                          v-if="
                            !checkIdRequiredResourceExists(
                              talent.talent.role.name
                            ) && false
                          "
                          size="sm"
                          class="mr-5"
                          variant="outline-info"
                          @click="addRequiredResource(talent.id)"
                          ><i class="fas fa-plus px-0 py-0"></i
                        ></b-button>
                      </div>
                      <p v-if="false" class="form__error-label">
                        Please select a clickup task ID
                      </p>
                    </td>

                    <td>
                      <div
                        class="d-flex"
                        :style="
                          talents.length == 1
                            ? 'height: 7rem; align-items: center;'
                            : ''
                        "
                      >
                        <v-select
                          style="width: 150px"
                          label="Status"
                          :items="interviewStatuses"
                          item-text="name"
                          item-value="id"
                          dense
                          deletable-chips
                          hide-details
                          outlined
                          v-model="talent.status"
                          :class="getStatusClass(talent.status)"
                          @change="statusDropdownChanged(talent)"
                        />
                        <div
                          class="d-flex justify-content-center ms-3 align-items-center"
                          style="height: 2rem"
                        >
                          <a
                            v-if="
                              talent.status.id == 'rejected' &&
                              talent.rejection_reason
                            "
                            class="navi-link d-flex justify-content-center mt-3"
                            @click.stop="
                              showRejectionReasonPopup(talent.rejection_reason)
                            "
                          >
                            <small
                              style="white-space: nowrap; font-weight: bold"
                            >
                              Rejection Reason
                            </small>
                          </a>
                        </div>
                        <div
                          class="d-flex justify-content-center ms-3 align-items-center"
                          style="height: 3rem"
                          v-if="
                            talent.feedback_blocked_reason ||
                            talent.acceptance_blocked_reason
                          "
                        >
                          <small
                            style="
                              white-space: nowrap;
                              font-weight: bold;
                              cursor: pointer;
                              color: red;
                            "
                            @click.stop="
                              showFeedbackPopup(
                                talent.feedback_blocked_reason ||
                                  talent.acceptance_blocked_reason
                              )
                            "
                          >
                            Unable to send the email
                          </small>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        v-if="talent.statusChanged && !hideAllActions"
                        class="d-flex justify-content-center"
                      >
                        <button
                          class="btn btn-info btn-sm mr-2"
                          @click="statusChanged(talent)"
                        >
                          Confirm
                        </button>
                        <button
                          class="btn btn-light btn-sm"
                          @click="cancelStatusChange(talent)"
                        >
                          Cancel
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h5
              class="my-5 text-muted text-center d-flex justify-content-center align-items-center"
              style="min-height: 40rem"
              v-else
            >
              No talents Yet
            </h5>
            <div class="pagination-container">
              <p
                v-if="talents && talents.length > 0"
                class="pagination-container__desc"
              >
                {{
                  $t("showing_results", {
                    length: talents.length,
                    total: talentsMeta.total,
                  })
                }}
              </p>
              <b-pagination
                v-if="talentsMeta && talentsMeta.last_page > 1"
                v-model="page"
                :total-rows="talentsMeta.total"
                :per-page="talentsMeta.per_page"
                align="center"
                last-number
                first-number
              ></b-pagination>
            </div>

            <!--end::Table-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    selectedProject: {
      type: Number,
    },
  },
  data() {
    return {
      page: 1,
      perPage: 8,
      hideAllActions: true,
      showError: false,
      talentToShowError: null,
      query: {},
      talentsPreviousValue: null,
    };
  },
  computed: {
    ...mapGetters({
      talents: "clients/getSelectedTalentsList",
      talentsMeta: "clients/getSelectedTalentsMeta",
      interviewStatuses: "clients/getTalentInterviewStatus",
      requiredResources: "clients/getProjectRequiredResources",
    }),
    projectId() {
      return this.$route.params.project_id;
    },
    talentsQuery() {
      return {
        page: this.page,
        perPage: this.perPage,
        ...this.query,
      };
    },
  },
  watch: {
    async selectedProject() {
      this.page = 1;
      const payload = {
        project_id: this.selectedProject,
        talentsQuery: this.talentsQuery,
      };
      await this.getSelectedTalentsList(payload).then(() => {
        this.talentsPreviousValue = this.talents.map((talent) => ({
          ...talent,
        }));
      });
      // await this.getProjectRequiredResources(payload);
    },
    async page() {
      const payload = {
        project_id: this.projectId,
        talentsQuery: this.talentsQuery,
      };
      await this.getSelectedTalentsList(payload).then(() => {
        this.talentsPreviousValue = this.talents.map((talent) => ({
          ...talent,
        }));
      });
    },
  },
  async mounted() {
    this.getSelectedTalents();
    this.getTalentStatuses();
  },
  methods: {
    ...mapActions({
      getSelectedTalentsList: "clients/getSelectedTalentsList",
      getProjectRequiredResources: "clients/getProjectRequiredResources",
      getTalentStatuses: "clients/getTalentStatuses",
      createRequiredResource: "clients/createRequiredResource",
      resyncMatchTalentWithRequieredResources:
        "clients/resyncMatchTalentWithRequieredResources",
    }),
    async getSelectedTalents() {
      const payload = {
        project_id: this.projectId,
        talentsQuery: this.talentsQuery,
      };
      await this.getSelectedTalentsList(payload).then(() => {
        this.talentsPreviousValue = this.talents.map((talent) => ({
          ...talent,
        }));
      });

      let selectionPanddingTalentsIds =
        JSON.parse(localStorage.getItem("selectionTalentsIds")) || [];

      this.talents.map((talent) => {
        if (
          talent.required_resource &&
          selectionPanddingTalentsIds.includes(talent.id)
        ) {
          selectionPanddingTalentsIds = selectionPanddingTalentsIds.filter(
            (id) => id !== talent.id
          );
        }
      });

      if (selectionPanddingTalentsIds.length > 0) {
        setTimeout(() => {
          this.getSelectedTalents();
        }, 5000);
      }

      localStorage.setItem(
        "selectionTalentsIds",
        JSON.stringify(selectionPanddingTalentsIds)
      );
    },
    checkSelectionTalentsIds(talentId) {
      let selectionTalentsIds =
        JSON.parse(localStorage.getItem("selectionTalentsIds")) || [];
      return selectionTalentsIds.includes(talentId);
    },
    showRejectionReasonPopup(reason) {
      this.$bvModal.msgBoxOk(reason, {
        title: "Rejection Reason",
        size: "md",
        buttonSize: "md",
        okVariant: "primary",
        headerClass: "p-4 border-bottom-1",
        footerClass: "p-2 border-top-0",
        bodyClass: "text-break",
        centered: true,
      });
    },
    showFeedbackPopup(reason) {
      this.$bvModal.msgBoxOk(
        reason.replace("Required resource is missing fields: ", ""),
        {
          title: "Required Resource Missing Fields",
          size: "md",
          buttonSize: "md",
          okVariant: "primary",
          headerClass: "p-4 border-bottom-1",
          footerClass: "p-2 border-top-0",
          bodyClass: "text-break",
          centered: true,
        }
      );
    },
    resyncRequieredResources(talent) {
      this.resyncMatchTalentWithRequieredResources({
        project_id: this.projectId,
        selection: talent.id,
        talentsQuery: this.talentsQuery,
      }).then(() => {
        let selectionTalentsIds =
          JSON.parse(localStorage.getItem("selectionTalentsIds")) || [];
        if (!selectionTalentsIds.includes(talent.id)) {
          selectionTalentsIds.push(talent.id);
          localStorage.setItem(
            "selectionTalentsIds",
            JSON.stringify(selectionTalentsIds)
          );
        }

        if (selectionTalentsIds.length > 0) {
          this.getSelectedTalents();
        }
      });
    },
    getStatusClass(status) {
      const statusClasses = {
        scheduled: "darkblue-status-selected-talent",
        accepted: "darkgreen-status-selected-talent",
        rejected: "darkturquoise-status-selected-talent",
        talent_no_show: "darkred-status-selected-talent",
        client_no_show: "darkred-status-selected-talent",
        completed: "darkorange-status-selected-talent",
        canceled: "darkred-status-selected-talent",
        expired: "crimson-status-selected-talent",
        hired: "tealblue-status-selected-talent",
      };
      return statusClasses[status.id ? status.id : status] || "";
    },
    checkIdRequiredResourceExists(roleName) {
      return this.requiredResources.some(
        (resource) => resource.name?.split(" | ")[1] === roleName
      );
    },
    formatDateTime(date) {
      return dayjs(date).format("DD/MM/YYYY");
    },
    statusDropdownChanged(talent) {
      if (talent.talent) {
        talent.statusChanged = true;
        this.hideAllActions = false;
      } else {
        this.showError = true;
        this.talentToShowError = talent;
      }
    },
    cancelStatusChange(talent) {
      const talentPrevious = this.talentsPreviousValue.find(
        (t) => t.id === talent.id
      );
      const currentTalent = this.talents.find((t) => t.id === talent.id);
      
      currentTalent.status = talentPrevious.status;

      talent.statusChanged = false;
      this.hideAllActions = true;
    },
    async statusChanged(talent) {
      const { id, status } = talent;
      await this.$store
        .dispatch("clients/updateTalentStatus", {
          selection: id,
          status: status.id ? status.id : status,
          project_id: this.projectId,
          talentsQuery: this.talentsQuery,
        })
        .then(() => {
          this.getSelectedTalents();
        });

      talent.statusChanged = false;
      this.showError = false;
      this.talentToShowError = null;
    },
    addRequiredResource(talentId) {
      this.createRequiredResource({
        project_id: this.projectId,
        selection: talentId,
        talentsQuery: this.talentsQuery,
      });
    },
  },
};
</script>

<style>
.darkblue-status-selected-talent .v-select__selections {
  color: darkblue !important;
}

.darkgreen-status-selected-talent .v-select__selections {
  color: darkgreen !important;
}

.darkturquoise-status-selected-talent .v-select__selections {
  color: #71ec71 !important;
}

.darkred-status-selected-talent .v-select__selections {
  color: darkred !important;
}

.darkorange-status-selected-talent .v-select__selections {
  color: darkorange !important;
}

.darkturquoise-status-selected-talent .v-select__selections {
  color: #71ec71 !important;
}

.crimson-status-selected-talent .v-select__selections {
  color: crimson !important;
}

.tealblue-status-selected-talent .v-select__selections {
  color: #006400 !important;
}
</style>
