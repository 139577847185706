var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"tab-content"},[(_vm.talents && _vm.talents.length)?_c('div',{staticClass:"table-responsive mb-0"},[_c('table',{staticClass:"table table-head-custom table-vertical-center table-head-bg table-borderless"},[_vm._m(1),_c('tbody',_vm._l((_vm.talents),function(talent){return _c('tr',{key:talent.id,style:(_vm.talents.length == 1 ? 'height: 7rem;' : '')},[_c('td',{attrs:{"title":`${talent.talent.full_name}`}},[_c('a',{attrs:{"href":talent.talent.dashboard_url,"target":"_blank"}},[_vm._v(_vm._s(talent.talent.full_name))])]),_c('td',[_vm._v(_vm._s(talent.talent.role.name))]),_c('td',[_vm._v(" "+_vm._s(talent.talent.is_qualified ? "Yes" : "No")+" ")]),_c('td',[_c('span',{staticClass:"text-center justify-content-center d-flex"},[_vm._v(" "+_vm._s(talent.created_at ? _vm.formatDateTime(talent.created_at) : "-")+" ")])]),_c('td',[_c('span',{staticClass:"text-center justify-content-center d-flex"},[_vm._v(" "+_vm._s(talent.interview?.meeting_start_time ? _vm.formatDateTime( talent.interview.meeting_start_time ) : "-")+" ")])]),_c('td',{style:(_vm.talents.length == 1 ? 'height: 7rem;' : '')},[_c('div',{staticClass:"d-flex justify-content-center"},[(talent.required_resource?.clickup_task_url)?_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(talent?.required_resource?.name),expression:"talent?.required_resource?.name",modifiers:{"hover":true}}],attrs:{"href":talent?.required_resource?.clickup_task_url,"target":"_blank"}},[_c('img',{staticClass:"d-flex align-items-start",staticStyle:{"height":"2.5rem","width":"2.5rem"},attrs:{"src":require("@/assets/images/clickup.png"),"alt":"clickup"}})])]):_vm._e(),(
                          !talent.required_resource?.clickup_task_url &&
                          _vm.checkSelectionTalentsIds(talent.id)
                        )?_c('div',[_vm._v(" in progress... ")]):_vm._e(),(
                          !talent.required_resource?.clickup_task_url &&
                          !_vm.checkSelectionTalentsIds(talent.id)
                        )?_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('v-btn',{staticClass:"ma-2 font-weight-bolder",attrs:{"color":"success"},on:{"click":function($event){return _vm.resyncRequieredResources(talent)}}},[_vm._v(" Auto-Match ")])],1):_vm._e(),(false)?_c('v-select',{staticClass:"mr-2",staticStyle:{"width":"250px"},attrs:{"label":"Clickup task ID","items":_vm.requiredResources,"item-text":"name","item-value":"id","deletable-chips":"","dense":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.statusDropdownChanged(talent)}},model:{value:(talent.required_resource),callback:function ($$v) {_vm.$set(talent, "required_resource", $$v)},expression:"talent.required_resource"}}):_vm._e(),(
                          !_vm.checkIdRequiredResourceExists(
                            talent.talent.role.name
                          ) && false
                        )?_c('b-button',{staticClass:"mr-5",attrs:{"size":"sm","variant":"outline-info"},on:{"click":function($event){return _vm.addRequiredResource(talent.id)}}},[_c('i',{staticClass:"fas fa-plus px-0 py-0"})]):_vm._e()],1),(false)?_c('p',{staticClass:"form__error-label"},[_vm._v(" Please select a clickup task ID ")]):_vm._e()]),_c('td',[_c('div',{staticClass:"d-flex",style:(_vm.talents.length == 1
                          ? 'height: 7rem; align-items: center;'
                          : '')},[_c('v-select',{class:_vm.getStatusClass(talent.status),staticStyle:{"width":"150px"},attrs:{"label":"Status","items":_vm.interviewStatuses,"item-text":"name","item-value":"id","dense":"","deletable-chips":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.statusDropdownChanged(talent)}},model:{value:(talent.status),callback:function ($$v) {_vm.$set(talent, "status", $$v)},expression:"talent.status"}}),_c('div',{staticClass:"d-flex justify-content-center ms-3 align-items-center",staticStyle:{"height":"2rem"}},[(
                            talent.status.id == 'rejected' &&
                            talent.rejection_reason
                          )?_c('a',{staticClass:"navi-link d-flex justify-content-center mt-3",on:{"click":function($event){$event.stopPropagation();return _vm.showRejectionReasonPopup(talent.rejection_reason)}}},[_c('small',{staticStyle:{"white-space":"nowrap","font-weight":"bold"}},[_vm._v(" Rejection Reason ")])]):_vm._e()]),(
                          talent.feedback_blocked_reason ||
                          talent.acceptance_blocked_reason
                        )?_c('div',{staticClass:"d-flex justify-content-center ms-3 align-items-center",staticStyle:{"height":"3rem"}},[_c('small',{staticStyle:{"white-space":"nowrap","font-weight":"bold","cursor":"pointer","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.showFeedbackPopup(
                              talent.feedback_blocked_reason ||
                                talent.acceptance_blocked_reason
                            )}}},[_vm._v(" Unable to send the email ")])]):_vm._e()],1)]),_c('td',[(talent.statusChanged && !_vm.hideAllActions)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('button',{staticClass:"btn btn-info btn-sm mr-2",on:{"click":function($event){return _vm.statusChanged(talent)}}},[_vm._v(" Confirm ")]),_c('button',{staticClass:"btn btn-light btn-sm",on:{"click":function($event){return _vm.cancelStatusChange(talent)}}},[_vm._v(" Cancel ")])]):_vm._e()])])}),0)])]):_c('h5',{staticClass:"my-5 text-muted text-center d-flex justify-content-center align-items-center",staticStyle:{"min-height":"40rem"}},[_vm._v(" No talents Yet ")]),_c('div',{staticClass:"pagination-container"},[(_vm.talents && _vm.talents.length > 0)?_c('p',{staticClass:"pagination-container__desc"},[_vm._v(" "+_vm._s(_vm.$t("showing_results", { length: _vm.talents.length, total: _vm.talentsMeta.total, }))+" ")]):_vm._e(),(_vm.talentsMeta && _vm.talentsMeta.last_page > 1)?_c('b-pagination',{attrs:{"total-rows":_vm.talentsMeta.total,"per-page":_vm.talentsMeta.per_page,"align":"center","last-number":"","first-number":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header py-3"},[_c('div',{staticClass:"card-title flex-column",staticStyle:{"justify-content":"space-evenly"}},[_c('h3',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v(" Selected Talents ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"text-left"},[_c('th',[_vm._v("The talent's name")]),_c('th',[_vm._v("Role")]),_c('th',[_vm._v("Qualified")]),_c('th',[_vm._v("Scheduled on")]),_c('th',[_vm._v("Interview Date")]),_c('th',[_vm._v("Clickup Task ID")]),_c('th',[_vm._v("Status")]),_c('th')])])
}]

export { render, staticRenderFns }